import React from "react";

export default function Spinner() {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <i className="fa-solid fa-bone fa-spin fa-3x text-darkBlue"></i>
      {/* <i className="fa-solid fa-circle-notch "></i> */}
    </div>
  );
}
