// import './App.css';
import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import Spinner from "./utilities/spinner";
import { BrowserRouter } from "react-router-dom";

const HomePage = lazy(() => import("./components/homePage/HomePage"));
const TermsPage = lazy(() => import("./components/terms/termsPage"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* <Route
              path="/home"
              component={
                <HomePage
                  servicesRef={servicesRef}
                  blogsRef={blogsRef}
                  aboutRef={aboutRef}
                />
              }
            /> */}
            <Route path="/terms" element={<TermsPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
